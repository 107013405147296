import styled from 'styled-components'
import check from '../../assets/images/icons/check.svg'

export const Cta = styled.section`
  display: flex;
  align-items: center;
  margin: 48px 16px;
  @media (min-width: 768px) {
    margin: 64px 16px;
  }
  & ~ & {
    padding-left: 0;
  }
  & > .MuiContainer-root {
    border-radius: 24px;
    background-image: ${(props) =>
      props?.bg?.image
        ? typeof props.bg.image === 'object'
          ? `url(${props.bg.image.publicURL})`
          : `url(${props.bg.image})`
        : `radial-gradient(164.93% 48.4% at 47.87% 41.82%, #32a679 0%, #125037 100%)`};
    background-position: ${(props) => props?.bg?.position || 'right 0'};
    background-size: cover;
    background: ${(props) => props?.bg?.color};
    ul {
      margin-bottom: 24px;
    }
    li {
      list-style-image: url(${check});
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 24px;
    }
  }
`
