import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import Benefits from '../components/Benefits'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/Carousel'
import ForBuyersCover from '../components/ForBuyersCover'
import Columns from '../components/Columns'
import Cta from '../components/Cta'
import Stack from '@mui/material/Stack'
//import Text from '../components/Text'
import Testimonials from '../components/Testimonials'
import Toolbar from '@mui/material/Toolbar'
import TrustedBy from '../components/TrustedBy'

// Content
import { contentforBuyers } from '../content/forBuyers'
import { logoSlideContent } from '../content/logoSlide'

const ForBuyersPage = () => {
  const { t } = useTranslation()
  
  return (
    <Layout headerWhite={true}>
      <Toolbar />
      <ForBuyersCover />
      <Benefits mode={'lightgp'} />
      <Columns content={contentforBuyers(t).columnsCover} />
      <Columns content={contentforBuyers(t).columns1} />
      <Columns content={contentforBuyers(t).columns2} />
      <Columns content={contentforBuyers(t).columns3} />
      <Columns content={contentforBuyers(t).columns4} />
      <Columns content={contentforBuyers(t).columns5} />
      <Stack direction='column' alignItems='center' spacing={2}>
        <TrustedBy mode={'default'} disposition={'row'} text={t('BUSINESSES')} show={false} />
        <Carousel className='mt-s-28' content={logoSlideContent('big', 'default', true).carousel} />
      </Stack>
      <Testimonials />
      <Cta content={contentforBuyers(t).cta} />
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='For Buyers'
    description='We enable buyers to source garments, fabrics, components and home textiles faster, smarter, safer, and more sustainable. Join the largest industry network.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ForBuyersPage
