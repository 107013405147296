import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useWindowSize from '../../utils/windowSize'

// Components
import Toolbar from '@mui/material/Toolbar'

// Styles
import './forBuyersCover.scss'

// Images
import backgroundImg from '../../assets/images/for-suppliers-cover/header-image-buyers-page.png'
import TrustedBy from '../TrustedBy'

const ForBuyersCover = () => {
  const responsive = useWindowSize().responsive
  var bgImg = responsive ? { background: '' } : { background: `url('${backgroundImg}') no-repeat right` }
  const { t } = useTranslation()
  return (
    <div className='forBuyersCover mx-auto'>
      <Toolbar />
      <div
        style={bgImg}
        className={'forBuyersCoverBgImg d-flex flex-row flex-wrap justify-content-center justify-content-lg-between'}
      >
        <div className='d-flex flex-column mt-s-80 forBuyersCoverLeft'>
          <h1 className='heading-1 text-grey-10'>{t('Save time. Save cost. Order sustainably.')}</h1>
          <span className='text-grey-10 font-size-18 ln-22 pt-s-16'>
            {t('We enable buyers to source garments, fabrics, components and home textiles ')}
          <span style={{ color: '#32A261', fontWeight: 'bold' }}>
            {t('faster, smarter, safer, and more sustainable')}
          </span>
            {t('. Join the ')}
          <span style={{ color: '#32A261', fontWeight: 'bold' }}>
            {t('largest industry network')}
          </span>
            {t(' and take the next step towards efficient and successful sourcing with our user-centric ')}
          <span style={{ color: '#32A261', fontWeight: 'bold' }}>
            {t('order placement system')}
          </span>
            {t('.')}
          </span>
          <a
            href={`${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`}
            className='btn btn-sm btn-primary mt-s-48 mb-md-s-68'
          >
            {t('Get started')}
          </a>
        </div>
        <div className='d-flex flex-column mt-auto overflowHidden'>
          <TrustedBy mode={'dark'} disposition={'column'} show={true} />
        </div>
      </div>
    </div>
  )
}

export default ForBuyersCover
