import styled from 'styled-components'
import { Box } from '@mui/material'

import check from '../../assets/images/icons/check-green.svg'

export const Columns = styled(Box)`
  display: flex;
  align-items: center;
  & > .MuiContainer-root {
    ul {
      margin-bottom: 24px;
    }
    li {
      list-style-image: url(${check});
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 24px;
    }
  }
`
