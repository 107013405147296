// Base
import * as React from 'react'
import Parser from 'html-react-parser'

// Components
import { Box, CardMedia, Container, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import Slider from '../Slider'
import CtaButton from '../CtaButton'

// Icons
import CheckIcon from '@mui/icons-material/Check'

// Styles
import * as S from './Columns.styles'

const Heading = ({ title, subhead, mode }) => {
  return (
    <Container maxWidth='md' sx={{ textAlign: 'center' }}>
      <Typography variant='subhead'>{subhead}</Typography>
      <Typography variant='h2' sx={{ color: mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten', mb: 5 }}>
        {title}
      </Typography>
    </Container>
  )
}

const Illustration = ({ src, alt, cover, link, ...attrs }) => {
  const content = (
    <CardMedia
      component='img'
      image={typeof src === 'object' ? src.publicURL : src}
      alt={alt}
      sx={{
        objectPosition: cover ? 'top' : 'center',
        objectFit: 'contain',
        marginTop: { xs: 6, md: cover ? 8 : 0 },
        maxHeight: cover && 292,
        maxWidth: '100%',
        width: 'auto',
        mx: 'auto',
        cursor: link ? 'pointer' : 'auto',
      }}
      {...attrs}
    />
  );

  // if image link
  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    );
  }

  return content;
};

// changed
const IllustrationSlide = ({ content }) => {
  return <img src={content.src} alt='ISPO Award' />
}

const Text = ({ title, description, mode, cover }) => {
  return (
    <>
      {title && (
        <Typography
          variant={cover ? 'h1' : 'h2'}
          sx={{
            color: mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten',
            mb: 2,
          }}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          variant='body1'
          component='div'
          sx={{ color: cover ? 'bluishGrey.ten' : 'bluishGrey.seven', mb: !cover && 2 }}
        >
          {Parser(description)}
        </Typography>
      )}
    </>
  );
};


const Buttons = ({ buttons, cover }) => {
  if (buttons.length === 1) {
    return (
      <CtaButton
        content={{
          color: cover && 'primary',
          size: cover && 'small',
          sx: {
            mt: cover && 6,
            width: cover && '100%',
            maxWidth: cover && 386,
          },
          ...buttons[0],
        }}
      />
    )
  } else {
    return (
      <Stack direction='row' sx={{ mx: 'auto', my: 3 }} spacing={1}>
        {buttons.map((button, i) => (
          <CtaButton key={i} content={button} />
        ))}
      </Stack>
    )
  }
}

const ListText = ({ list, mode }) => {
  return (
    <List dense sx={{ color: mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten', my: 1, mt: { xs: 2, md: 0 } }}>
      {list.map((item, i) => (
        <ListItem disablePadding key={i}>
          <ListItemIcon>
            <CheckIcon sx={{ color: mode === 'dark' ? 'bluishGrey.eight' : 'silkyGreen.main', fontSize: 28, mr: 1 }} />
          </ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  )
}

const Columns = ({ content, showButton = true }) => {
  return (
    <S.Columns sx={{ my: { xs: 6, md: 8 }, mx: content?.mode === 'gray' && 2 }}>
      <Container
        disableGutters={!(content?.mode === 'gray')}
        maxWidth='xl'
        sx={{
          borderRadius: 3,
          py: content?.mode === 'gray' && 5,
          backgroundColor: content?.mode === 'gray' && 'bluishGrey.one',
          maxWidth: content?.cover && { md: 'md', lg: 'xl' },
        }}
      >
        {content?.heading && <Heading {...content.heading} mode={content?.mode} />}
        <Box
          className={`row align-items-center justify-content-between ${
            content?.direction === 'reverse' ? 'flex-row-reverse' : ''
          }`}
        >
          <Box
            className={`col-${content.cover ? 'lg' : 'md'}-${(content?.sizes && content?.sizes[0]) || 6}`}
            sx={{ textAlign: { xs: 'center', md: content.cover ? 'center' : 'left', lg: 'left' } }}
          >
            {content?.text && <Text {...content.text} mode={content?.mode} cover={content.cover} />}
            {content?.list && <ListText list={content?.list} mode={content?.mode} />}
            {content?.buttons && <Buttons buttons={content.buttons} cover={content.cover} />}
          </Box>
          <Box className={`col-${content.cover ? 'lg' : 'md'}-${(content?.sizes && content?.sizes[1]) || 6}`}>
            {content?.illustration && <Illustration {...content.illustration} cover={content.cover} />}
            {content?.slider && (
              <Slider content={content.slider}>
                <IllustrationSlide />
              </Slider>
            )}
          </Box>
        </Box>
      </Container>
    </S.Columns>
  )
}

export default Columns
