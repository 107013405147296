//import Cover from '../assets/images/covers/general-page-background.svg'
import Image01 from '../assets/images/general-page/find-a-new-supplier.png'
import Image02 from '../assets/images/general-page/orderPayTrackYourOrder.png'
import Image03 from '../assets/images/general-page/request-and-compare-quotes.png'
import Image04 from '../assets/images/general-page/sustainability.png'
import Image05 from '../assets/images/general-page/request-price-while-exploring-products.png'
import Image06 from '../assets/images/general-page/source-ustainably.png'
import background1 from '../assets/images/cta/background1.jpg'

export const contentforBuyers = (t) => {
  return {
    columnsCover: {
    },
    columns1: {
      direction: 'reverse',
      sizes: [5, 6],
      illustration: {
        src: Image01,
        alt: 'Find a new supplier',
      },
      text: {
        title: 'Find a new supplier',
      },
      list: [
        'Access the world’s largest B2B sourcing platform for apparel, fabric, trims, yarns and home textiles sourcing.',
        'Save time, effort, and costs by finding new business partners online without traveling.',
        'Explore verified company and user profiles with trusted information and directly get in touch.',
        'Bookmark and send connection requests to potential business partners and leads.',
      ],
      buttons: [
        {
          text: 'Explore network',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns2: {
      direction: 'default',
      sizes: [5, 6],
      illustration: {
        src: Image02,
        alt: 'Order, pay and track your order',
      },
      text: {
        title: 'Order, pay and track your order',
      },
      list: [
        'Eliminate time-consuming email threads and chats. Find everything you need to order from start to finish.',
        'Start an order by specifying quantity, unit price, Incoterms, packaging, and delivery dates.',
        'Experience a unique payment management using our integrated escrow service and e-wallets.',
        'Release funds per agreed terms with sellers, finalized upon successful delivery. Track the status of order.',
      ],
      buttons: [
        {
          text: 'Start project',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns3: {
      direction: 'reverse',
      sizes: [5, 6],
      illustration: {
        src: Image03,
        alt: 'Request and compare quotes',
      },
      text: {
        title: 'Request and compare quotes',
      },
      list: [
        'Create targeted requests for quotations to find the best partner for your project.',
        'Receive various proposals to get your targeted price and quality.',
        'Find out what your product would cost with a different supplier or even in another country.',
        'Save valuable time and resources that would otherwise be spent on extensive research and negotiations.',
      ],
      buttons: [
        {
          text: 'Start sourcing',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns4: {
      direction: 'default',
      sizes: [5, 6],
      illustration: {
        src: Image04,
        alt: 'Source sustainably',
      },
      text: {
        title: 'Source sustainably',
      },
      list: [
        'Meet sustainability and ESG requirements by choosing sustainable manufacturers.',
        'Ensure compliance by accessing verified certificates on the platform.',
        'Monitor the validity of suppliers’ certificates with real-time data updates.',
        'Gain knowledge about various compliance certificates and sustainability standards.',
      ],
      buttons: [
        {
          text: 'Start sourcing',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns5: {
      direction: 'reverse',
      sizes: [5, 6],
      illustration: {
        src: Image05,
        alt: 'Request Prices While Exploring Products',
      },
      text: {
        title: 'Request Prices While Exploring Products',
      },
      list: [
        'Streamline your purchasing process by effortlessly requesting and receiving pricing information.',
        'Explore supplier´s digital showrooms with ease and convenience. Browse through products and request prices.',
        'Request accurate pricing by providing specific order details such as total quantity, unit, delivery requirements, expected delivery date, and any additional details.',
        'Receive an immediate notification the moment your price request is answered by the supplier.',
      ],
      buttons: [
        {
          text: 'Discover products and request prices',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns6: {
      direction: 'default',
      sizes: [5, 6],
      illustration: {
        src: Image06,
        alt: 'Source Sustainably',
      },
      text: {
        title: 'Source Sustainably',
      },
      list: [
        'Search for sustainable manufacturing companies. Make informed decisions about your supply chain.',
        'Gain knowledge about various compliance certificates and standards related to sustainability.',
        'Track the validity of your supplier’s certificates with up-to-date data.',
        'Save time by efficiently reviewing verified compliance certificates on the platform.',
      ],
      buttons: [
        {
          text: 'See products',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    cta: {
      title: 'Join now and start sourcing',
      subhead: 'Try For Free',
      bg: {
        image: background1,
      },
      buttons: [
        {
          text: 'Sign up',
          href: 'SIGNUP',
          color: 'primary',
        },
      ],
    },
  }
}
