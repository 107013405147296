import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Parser from 'html-react-parser'

// Styles
import './trustedBy.scss'

// Images
import users from '../../assets/images/trusted-by/users-section-image.png'

// Content
import { logoSlideContent } from '../../content/logoSlide'
import Carousel from '../Carousel'

const TrustedBy = ({ mode, disposition, variant, text, show }) => {
  const { t } = useTranslation()
  return (
    <div
      className={
        'trustedBy d-flex flex-column flex-wrap ' +
        (disposition === 'row' ? 'trustedByRow mx-auto' : 'align-items-center align-items-lg-end trustedByColumn ') +
        (variant === 'nologos' && ' trustedByNologos')
      }
    >
      <b className='sub-headline text-grey-8'>{Parser(t('Trusted by'))} {text}</b>
      <div
        className={
          'd-flex flex-wrap pt-s-16 ' + (disposition === 'row' ? 'flex-row align-items-center' : 'flex-column')
        }
      >
        <div className={disposition === 'row' ? 'mx-auto align-items-center' : ' m-lg-0 ms-lg-auto mx-auto'}>
          <img src={users} alt={'Foursource Users'} width={72} className='me-s-8' />
          <b className={mode === 'light' ? 'text-grey-1' : 'text-grey-5'}>{Parser(t('45K+ companies:'))}</b>
        </div>
        {show && <div className={(disposition === 'row' ? 'mx-auto mt-sm-s-10' : 'mt-s-10')}>
          <Carousel content={logoSlideContent('small', 'white').carousel} />
        </div>
        }
      </div>
    </div>
  )
}

export default TrustedBy
