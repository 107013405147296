import * as React from 'react'
import Header from './Header'
import Footer from './Footer'
import CookiesMessage from './Cookies'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { theme } from '../theme'
import Scripts from './scripts'

import GlobalStyle from '../assets/styles/globalStyles'

const Layout = (props) => {
  return (
    <>
      <Scripts />
      <GlobalStyle />
      <CookiesMessage />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!props.hideStructure && <Header headerWhite={props.headerWhite} activePage={props.activePage} />}
        <main>{props.children}</main>
        {!props.hideStructure && <Footer mode={'light'} />}
      </ThemeProvider>
    </>
  )
}

export default Layout
